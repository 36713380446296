<template>
	<modal name="user-detail" :adaptive="true" width="90%" :max-width="550" height="auto" :reset="true" @before-open="beforeOpen" @before-close="beforeClose">
		<div v-if="!imageOnly" class="user-detail-modal">
			<form ref="form" @submit.prevent="submit">
				<h1 v-if="!imageOnly">User details</h1>
				<h1 v-if="imageOnly">Profile picture</h1>
				<div v-if="!imageOnly" class="columns">
					<div class="column is-half">
						<div class="field">
							<label class="label">First name</label>
							<div class="control">
								<input v-model="firstName" class="input" type="text" name="firstName" placeholder="First name" />
							</div>
						</div>
					</div>
					<div class="column is-half">
						<div class="field">
							<label class="label">Last name</label>
							<div class="control">
								<input v-model="lastName" class="input" type="text" name="lastName" placeholder="Last name" />
							</div>
						</div>
					</div>
				</div>
				<div class="file" :class="{ 'has-name': image.name }">
					<label class="file-label">
						<input class="file-input" type="file" name="files" @change="fileChange" />
						<span class="file-cta">
							<span class="file-label">
								Choose a profile picture…
							</span>
						</span>
						<span v-if="image.name" class="file-name">{{ image.name }}</span>
					</label>
				</div>
				<div class="buttons">
					<button type="submit" class="button is-primary" :class="{ 'is-loading': submitting }">Submit</button>
					<a class="button is-primary is-outlined" @click="cancel()">Cancel</a>
				</div>
			</form>
		</div>
		<div v-if="imageOnly" class="user-detail-modal user-image-modal">
			<h1 v-if="imageOnly">Profile picture</h1>
			<p>Your current profile picture</p>
			<div class="image">
				<img v-if="userAvatar" :src="userAvatar | imageUrl('small')" />
			</div>
			<button id="pick-avatar" class="button is-primary">Choose a profile picture…</button>
			<AvatarCropper ref="avatarcropper" trigger="#pick-avatar" :upload-handler="submitImage" :labels="{ submit: 'Submit', cancel: 'Cancel' }" @uploaded="handleUploaded" />
			<div v-if="imageUploading" class="loader-wrapper is-active">
				<div class="loader is-loading"></div>
			</div>
		</div>
	</modal>
</template>

<script>
	import { APIURL } from '../consts';
	import session from '../utils/session';
	import AvatarCropper from 'vue-avatar-cropper';
	import $http from '../utils/http';

	export default {
		name: 'UserDetails',
		components: { AvatarCropper },
		data() {
			return {
				firstName: null,
				lastName: null,
				image: {
					name: null,
					file: null,
				},
				submitting: false,
				imageOnly: false,
				imageUploading: false,
				session: session.state,
			};
		},
		computed: {
			userAvatar() {
				return this.session.user.image;
			},
			userId() {
				return this.session.user.id;
			},
		},
		methods: {
			beforeOpen(event) {
				this.imageUploading = false;
				if (event.params.imageOnly) {
					this.imageOnly = true;
				} else {
					this.firstName = event.params.firstName;
					this.lastName = event.params.lastName;
				}
			},
			cancel() {
				this.$modal.hide('user-detail');
			},
			submit() {
				// ToDo: refactor or remove - endpoint was updated
				this.submitting = false;
				const formElement = document.querySelector('form');
				const request = new XMLHttpRequest();
				const formData = new FormData();
				const formElements = formElement.elements;
				const data = {};

				for (let i = 0; i < formElements.length; i++) {
					const currentElement = formElements[i];
					if (!['submit', 'file'].includes(currentElement.type)) {
						data[currentElement.name] = currentElement.value;
					} else if (currentElement.type === 'file') {
						if (currentElement.files.length === 1) {
							const file = currentElement.files[0];
							formData.append(`files.${currentElement.name}`, file, file.name);
						} else {
							for (let i = 0; i < currentElement.files.length; i++) {
								const file = currentElement.files[i];

								formData.append(`files.${currentElement.name}`, file, file.name);
							}
						}
					}
				}

				formData.append('data', JSON.stringify(data));

				request.open('PUT', `${APIURL}/user-info`);
				request.setRequestHeader('Authorization', `Bearer ${session.state.key}`);
				request.send(formData);
				request.onreadystatechange = () => {
					if (request.readyState === 4) {
						if (request.status === 204) {
							if (!this.imageOnly) {
								session.updateName(this.firstName, this.lastName);
							}
							this.$parent.updateName();
							this.$modal.hide('user-detail');
						}
					}
				};
			},
			submitImage(cropper) {
				cropper.getCroppedCanvas().toBlob(blob => {
					this.submitting = false;
					this.imageUploading = true;
					const request = new XMLHttpRequest();
					const formData = new FormData();
					const data = {};
					formData.append(`files.files`, blob, this.$refs.avatarcropper.filename ? this.$refs.avatarcropper.filename : `avatar-${this.userId}.jpg`);
					formData.append('data', JSON.stringify(data));
					request.open('PUT', `${APIURL}/user-info/avatar`);
					request.setRequestHeader('Authorization', `Bearer ${session.state.key}`);
					request.send(formData);
					request.onreadystatechange = () => {
						if (request.readyState === 4) {
							if (request.status === 204) {
								$http.get(`/user-info-light`).then(({ data }) => {
									session.updateImage(data.image);
								});
								this.$parent.updateName();
								this.$modal.hide('user-detail');
							}
						}
					};
				});
			},
			fileChange(event) {
				if (event.target && event.target.files) {
					this.image.file = event.target.files[0];
					this.image.name = event.target.files[0].name;
				}
			},
			beforeClose() {
				this.firstName = null;
				this.lastName = null;
				this.image = {
					name: null,
					file: null,
				};
				this.submitting = false;
			},
			handleUploaded(response) {
				console.log(response);
			},
		},
	};
</script>
