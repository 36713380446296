<template>
	<div class="user-page__aside">
		<div class="user-page__userbox">
			<div class="image">
				<img v-if="hasFrame" :src="platformSettings.profileFrameImage | imageUrl" class="frame" />
				<inline-svg v-if="!userImage && userGender === 'Male'" :src="require('../assets/user_male.svg')" width="25" />
				<inline-svg v-else-if="!userImage && userGender === 'Female'" :src="require('../assets/user_female.svg')" width="25" />
				<inline-svg v-else-if="!userImage" :src="require('../assets/user_generic.svg')" width="25" />
				<img v-else :src="userImage | imageUrl('small')" />
				<a v-if="$route.name === 'EditProfile' && editMode" @click="updateUser">
					<inline-svg :src="require('../assets/camera.svg')" width="20"></inline-svg>
					<span>Change</span>
				</a>
			</div>
			<div>
				<strong>{{ userName }}</strong>
				<span>{{ userProfession }}</span>
				<span>{{ userCountry }}</span>
			</div>
		</div>
		<div class="user-page__menu">
			<ul>
				<li>
					<router-link :to="{ name: 'EditProfile' }" :class="{ 'is-active': $route.name === 'EditProfile' }">
						<inline-svg :src="require('../assets/edit2.svg')" width="26"></inline-svg>
						<span>Edit Profile</span>
					</router-link>
				</li>
				<li v-if="activityChallengeVisible">
					<router-link :to="{ name: 'ActivityChallenge' }" :class="{ 'is-active': $route.name === 'ActivityChallenge' }">
						<inline-svg :src="require('../assets/game.svg')" width="26" />
						<span>Activity Challenge</span>
					</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'Attendees' }" :class="{ 'is-active': $route.name === 'Attendees' }">
						<inline-svg :src="require('../assets/conference.svg')" width="26"></inline-svg>
						<span>Attendees List</span>
					</router-link>
				</li>
				<li v-if="showMatchmaking">
					<router-link :to="{ name: 'Matchmaking' }" :class="{ 'is-active': $route.name === 'Matchmaking' }">
						<inline-svg :src="require('../assets/direction.svg')" width="26"></inline-svg>
						<span>Matchmaking</span>
					</router-link>
				</li>
				<li v-if="showRecommendations">
					<router-link :to="{ name: 'Recommendations' }" :class="{ 'is-active': $route.name === 'Recommendations' }">
						<inline-svg :src="require('../assets/hint.svg')" width="26"></inline-svg>
						<span>Recommendations</span>
					</router-link>
				</li>
				<li v-if="platformSettings.chat">
					<router-link :to="{ name: 'Chat' }" :class="{ 'is-active': $route.name === 'Chat' }">
						<inline-svg :src="require('../assets/chat-bubble.svg')" width="26"></inline-svg>
						<span>Chat Messages</span>
					</router-link>
				</li>
				<li v-if="showCertificates">
					<router-link :to="{ name: 'Certificates' }" :class="{ 'is-active': $route.name === 'Certificates' }">
						<inline-svg :src="require('../assets/diploma.svg')" width="26"></inline-svg>
						<span>Attendance & CME Certificates</span>
					</router-link>
				</li>
				<li>
					<router-link :to="{ name: 'AgendaProfile' }" :class="{ 'is-active': $route.name === 'AgendaProfile' }">
						<inline-svg :src="require('../assets/timetable.svg')" width="26"></inline-svg>
						<span>My Agenda</span>
					</router-link>
				</li>
				<li v-if="bagVisible">
					<router-link :to="{ name: 'VirtualBagProfile' }" :class="{ 'is-active': $route.name === 'VirtualBagProfile' }">
						<inline-svg :src="require('../assets/briefcase.svg')" width="26"></inline-svg>
						<span>My Digital Congress Bag</span>
					</router-link>
				</li>
			</ul>
		</div>
		<Advert type="profile"></Advert>
		<span></span>
		<UserDetails />
	</div>
</template>

<script>
	import $http from '../utils/http';
	import session from '../utils/session';
	import UserDetails from '../components/UserDetails';
	import platformSettings from '../utils/platformSettings';
	import Advert from './Advert';

	export default {
		name: 'UserMenu',
		components: { Advert, UserDetails },
		props: ['editMode'],
		data() {
			return {
				session: session.state,
				platformSettings: platformSettings.state,
				image: null,
				bagVisible: false,
			};
		},
		computed: {
			userName() {
				return `${this.session.user.firstName} ${this.session.user.lastName}`;
			},
			userProfession() {
				if (typeof this.session.user.user_profession === 'object') {
					return this.session.user.user_profession?.name;
				}
				return '';
			},
			userCountry() {
				if (typeof this.session.user.user_country === 'object') {
					return this.session.user.user_country?.name;
				}
				return '';
			},
			userGender() {
				return this.session.user.gender;
			},
			userImage() {
				return this.session.user.image ? this.session.user.image : this.image;
			},
			showCertificates() {
				return this.platformSettings.certificatesDownloadEnabled === true;
			},
			activityChallengeVisible() {
				return this.platformSettings && this.platformSettings.gamification_enabled;
			},
			hasFrame() {
				return (
					this.session.user.eaaci_membership === true &&
					this.session.user.profile_frame_enabled === true &&
					this.platformSettings.profileFrameEnabled &&
					this.platformSettings.profileFrameImage
				);
			},
			showMatchmaking() {
				return this.platformSettings.matchmakingEnabled === true;
			},
			showRecommendations() {
				return this.platformSettings.recommendationsEnabled === true;
			},
		},
		created() {
			this.loadData();
			$http.get('page-my-virtual-bag').then(
				() => {
					this.bagVisible = true;
				},
				() => {
					this.bagVisible = false;
				}
			);
		},
		methods: {
			loadData() {
				if (!this.userImage) {
					$http.get(`/user-info-light`).then(({ data }) => {
						this.image = data.image;
						session.updateImage(this.image);
					});
				}
			},
			updateUser() {
				this.$modal.show(
					'user-detail',
					{
						imageOnly: true,
					},
					{
						width: '80%',
						maxWidth: 600,
						height: 'auto',
						adaptive: true,
					}
				);
			},
			updateName() {
				this.loadData();
			},
		},
	};
</script>
